.huevito {
  width: 60px;
}
.EasterEgg {
  position: fixed;
  flex-direction: column;
  left: 12px;
  bottom: 20px;
  width: 300px;
  height: 70px;
  display: flex;
}
.sinBordes:focus {
  border: none;
  outline: none;
}

.sinBordes {
  border: none;
  outline: none;
  background: transparent;
  margin-top: -40px;
}
.sinBordes:active {
  border: none;
}
