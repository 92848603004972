#menuMobile {
  width: 28vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0px;
  transform: translate(-1600px, 0px);
  z-index: 101;
}
.MenuOn {
  animation: entraMenu 0.4s;
  animation-fill-mode: forwards;
  cubic-bezier: (0.17, 0.67, 0.83, 0.67);
}
.MenuOff {
  animation: saleMenu 0.4s;
  animation-fill-mode: forwards;
  cubic-bezier: (0.17, 0.67, 0.83, 0.67);
}
@keyframes entraMenu {
  from {
    transform: translate(-1600px, 0px);
  }
  to {
    transform: translate(0px, -0px);
  }
}

@keyframes saleMenu {
  from {
    transform: translate(0px, 0px);
  }
  to {
    transform: translate(-1600px, 0px);
  }
}
.menuMobile-botonSalir {
  position: absolute;
  top: 10px;
  right: -50px;
}

.infoCliente {
  font-family: "Abril Fatface", cursive;
  color: white;
}

.infoClientebtn {
  font-family: "Abril Fatface", cursive;
  color: white;
  background: transparent;
  font-size: 25px;
  margin-bottom: 5px;
  border: transparent;
  cursor: pointer;
  padding-left: 0px;
}
.infoClientebtn:hover {
  color: #01ff00;
}

.infoCliente li {
  list-style: none;
}
ul {
  list-style: none;
  margin-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
}
.infoCliente a {
  color: white !important;
}
.infoCliente a:hover {
  color: #01ff00;
}
