.centradoFlex {
  margin-top: 30px !important;
  background: #04a1dd;
  padding-top: 17px;
  border-radius: 14px;
  justify-content: center;
}
.blanco {
  color: white;
}
.SHheader {
  background: deeppink;
  border-radius: 30px;
  padding-bottom: 30px;
}

.SHheaderRosa {
  background: deeppink;
  border-radius: 30px;
  color: white !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.SHheader-content {
  width: 100%;
  padding-left: 100px;
}

.SHheader-content-right {
  width: 100%;
  padding-right: 100px;
}
.SHbtnComprar {
  background: #14b2d3;
  color: white;
  padding: 20px;
  font-size: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.img-SH {
  width: 80%;
}
.img-SHlogo {
  width: 100%;
}

.SHbtnComprar {
  border-radius: 15px;
}
.SHsub {
  font-weight: 700;
}
.SHtxt {
  font-size: 18.5px;
  line-height: 20px;
}
.SHpregunta {
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
}

.SHpreguntah1 {
  font-size: 30px;
  font-weight: 700;
  margin: 0px;
}

.reviews {
  min-height: 200px;
  background: white;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.reviews-txt {
  font-size: 14px;
  padding: 16px;
  line-height: 17px;
  display: flex;
  flex-direction: column;
}

.reviews-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.reviews-name {
  font-weight: 700;
  font-style: italic;
}
.reviews-txt p {
  margin: 0px;
}
.fafafa {
  background: #fafafa;
}
.top50 {
  margin-top: 50px !important;
}
.amarello {
  background: #ffd400;
}

a {
  text-decoration: none;
}

/* .flexCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
} */

/* .flexCenterOk {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70vh;
} */

.SH-ico {
  width: 120px;
  padding: 20px;
  margin-top: 30px;
}
.SH-ico-txt {
  padding: 10px 30px 0px 30px;
}
.punch {
  text-align: center;
}
.quees {
  color: white;
  font-size: 40px;
  font-weight: 700;
}
.center {
  text-align: center;
}
.paravos {
  padding: 100px 0px 100px 0px;
}
.right {
  text-align: right;
}
.paravosback {
}

.alumnos {
  height: 800px;
  padding-top: 100px;
}

.hrt {
  margin-bottom: 40px !important;
}
.hrf {
  margin-top: 40px !important;
}
.SHrespuesta {
  padding: 0px 50px 50px 0px;
}
.b50 {
  margin-bottom: 50px;
}
.top50 {
  margin-top: 50px;
}
.on {
  display: none;
}
.Primero {
  margin-top: 50px;
}

.titEB {
  width: 80%;
}
@media only screen and (max-width: 600px) {
  .arribaTop {
    margin-top: 50px;
  }
  .porJS {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  .flexCenter,
  .flexCenterOk {
    height: auto;
  }
  .on {
    display: block;
  }
  .off {
    display: none;
  }

  .alumnos {
    height: auto;
  }
  .SHheader-content {
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .SHheader {
    height: auto;
  }
  .SHbtnComprar {
    display: inline;
  }
  .SHheader-content h2 {
    font-size: 20px;
    padding-bottom: 20px;
  }
  h1 {
    text-align: center;
  }
  .fafafa {
    background: #fafafa;
    margin-top: -30px;
  }
  .SHheader-content-right {
    padding: 20px;
  }
  .quees {
    font-size: 25px;
  }
  .SHheaderRosa {
    height: auto;
    margin-top: 30px;
  }
}

.btcom {
  text-decoration: none;
}

.comboOferta {
  text-align: center !important;
}

.precioFinal {
  font-weight: 800;
}
.precioViejo {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.comboOferta p {
  line-height: 18px;
}

.centerX {
  flex-direction: column;
  display: flex;
  align-items: center;
}
.wl {
  width: 75%;
  cursor: pointer;
  filter: grayscale(100%);
}

.wlx {
  width: 80%;
  filter: grayscale(100%);
  cursor: pointer;
}

.wl:hover,
.wlx:hover {
  opacity: 1;
  filter: grayscale(0%);
}
.dale {
  opacity: 1;
  filter: grayscale(0%);
}

h6 {
  font-weight: 800 !important;
  font-size: 18px !important;
}
.transferencia {
  border: solid 1px white;
  border-radius: 15px;
  padding: 15px;
}
.blancox {
  color: #1f2426;
  text-decoration: none;
}
