.contenedorMisCursos {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px;
}

.imgTitCursos {
  height: 50px;
  padding: 20px 20px 20px 0px;
  max-width: 100%;
}
