.containerLoco {
  display: flex;
  min-height: 90vh;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "Heebo", sans-serif;
  margin: 0 auto;
}
.heroMobile {
  color: black;
}
.bajadaTxt {
  text-align: center;
}
.todoSH24 {
  background: #015bc6;
  color: white;
  padding: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.item {
  padding: 15px;
  max-width: 270px;
}
.imgItem {
  width: 200px;
}
.txtItem {
  margin-top: 5px;
  color: white;
}
.hero {
  display: flex;
  min-height: calc(40vh - 60px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Heebo", sans-serif;
}
.bajadita {
  text-align: center;
  width: 40%;
  margin: 0 auto;
  margin-top: 70px;
}
.EasterIMG {
  *width: 100%;
}
.contenedorBajada {
  height: 100%;
  padding: 15px;
  font-size: 23px;
  text-align: center;
  color: black;
}
.imgPrincipal {
  width: 35%;
  padding-bottom: 20px;
}
.imgBirdTxt {
  width: 25%;
  padding-top: 20px;
  padding-bottom: 20px;
}
.imgBird {
  width: 32%;
  padding-bottom: 20px;
}

.imagenesBird {
  display: flex;
  flex-direction: row;
}

/* @media only screen and (max-width: 1800px) {
  .imgPrincipal {
    width: 40%;
  }
  .imgBirdTxt {
    width: 40%;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .imgBird {
    width: 50%;
    padding-bottom: 20px;
  }
  .bajada {
    max-width: 40%;
    font-size: 16px;
  }
} */

.heroMobile img {
  width: 100%;
}

.bajadam {
  font-size: 16px;
  text-align: center;
  margin-top: -40px;
}
@media only screen and (max-width: 800px) {
  .item {
    padding: 15px;
    max-width: 45%;
  }
  .imgItem {
    width: 100%;
  }
  .imgPrincipal {
    width: 95%;
  }
  .imgBirdTxt {
    width: 95%;
    padding-top: 40px;
    padding-bottom: 20px;
  }
  .imgBird {
    width: 95%;
    padding-bottom: 20px;
  }
  .bajada {
    max-width: 95%;
    font-size: 16px;
  }

  .hero {
    justify-content: flex-start;
    height: calc(70vh - 60px);
  }
  .bajadita {
    width: 90%;
  }
}
