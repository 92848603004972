.header {
  background-color: #0066cc;
  color: white;
  padding: 1.5em 0;
  text-align: center;
}
.main {
  padding: 2em;
  max-width: 800px;
  margin: 0 auto;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.title {
  font-size: 2em;
  margin-bottom: 0.5em;
  color: #0066cc;
}
.list {
  margin: 1em 0;
  padding-left: 1.5em;
}
.list-item {
  margin-bottom: 0.5em;
}
.prices {
  margin: 1em 0;
  padding: 1em;
  background: #e6f7ff;
  border: 1px solid #b3e0ff;
  border-radius: 8px;
}
.footer {
  text-align: center;
  margin-top: 2em;
  font-size: 0.9em;
  color: #666;
}
