.visto {
  background: red;
}
.enPlay {
  margin-left: 10px;
}
.verSection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px 0px 15px;
  height: 90vh;
}

.controles {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-top: 15px;
}
.controles .btn-loco,
.verSection button {
  color: white;
}
.verSection button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border: solid 1px #01ff00;
  font-family: "Abril Fatface", cursive;
  border-radius: 5px;
}
.controles {
  display: flex;
  opacity: 0.1;

  padding-right: 6px;
}
.controles:hover {
  opacity: 1;
}
.controles button {
  margin: 10px 3px 10px 3px;
}

.soporteVideos {
  z-index: 5;
  display: flex;
  flex-direction: column;
}
.listadin {
  height: 400px;
  overflow-y: scroll;
  margin: 0px;
  padding: 0px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  margin-bottom: -10px;
  padding: 15px;
}
.clipi {
  height: 25px;
  cursor: pointer;
  margin-top: 10px;
  margin-left: 2px;
}

.listadin ul {
  padding: 0px;
  margin: 0px;
}

.listadin .visto {
  background: #01ff00;
  color: black;
}
.titilo-listadito {
  background: white;
  color: black;
  font-weight: bolder;
  text-align: center;
}
