.loader-wraper2 {
  background: #fed536;
  height: 100vh;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  /* *filter: blur(4px); */
  top: 0px;
  z-index: 10;
}
.loader-img {
  width: 125px;
}
.Entrarcongoogle {
  background: black;
  display: flex;
  align-items: center;
  border: transparent;
  font-size: 16px;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  border-radius: 6px;
  color: white;
  justify-content: center;
  width: 300px;
}
.Entrarcongoogle img {
  margin-right: 5px;
}
.entrada {
  width: 300px;
  padding: 5px 10px 5px 10px;
  border-radius: 6px;
  border: transparent;
  height: 40px;
  box-sizing: border-box;
}
.Entra {
  padding: 5px 10px 5px 10px;
  height: 40px;
  width: 100px;
  background: red;
  color: white;
  border: transparent;
  cursor: pointer;
}
.Entra:hover {
  opacity: 0.8;
}
.olvidaste {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 5px;
}
.olvidastebtn {
  background: transparent;
  border: transparent;
  cursor: pointer;
  font-size: 15px;
}
.cerrarx {
  background: transparent;
  border: transparent;
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 30px;
  cursor: pointer;
}
.cerrarx:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 800px) {
  .loader-wraper2 {
    width: 100%;
  }
  .creame {
    font-size: 14px;
    padding: 5px;
    padding-bottom: 8px;
  }
  .logoLink {
    width: 140px;
  }
}
