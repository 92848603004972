/* body {
  margin: 0px;
  background: black;
  padding: 100px;
} */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-family: "Roboto";
  font-size: 18px;
}
a {
  text-decoration: none;
}

.go3958317564 {
  background: url(../statics/heydevbot.png) no-repeat;
  background-size: 63px;
  height: 60px;
  padding-left: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}
a:visited {
  color: none;
}
body {
  color: #1f2426;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
}
.todoGris {
  animation: ponerTodoGris 0.7s;
}
@keyframes ponerTodoGris {
  from {
    filter: grayscale(0%);
  }
  to {
    filter: grayscale(100%);
  }
}
a:visited {
}
.timer {
  text-align: center;
  color: greenyellow;
  text-shadow: 3px 1px#d22d65;
  font-size: 50px;
  font-family: monospace;
}
.accordion__button {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.on {
  display: none;
}

@media only screen and (max-width: 800px) {
  .on {
    display: block !important;
  }
  #menuMobile {
    width: 100vw;
  }
  .off {
    display: none !important;
  }
  .foto-de-perfil {
    width: 100%;
    padding: 10px;
  }
  .soportePaquete {
    width: 90% !important;
  }
  .dataCompra {
    width: 80% !important;
  }
  .carousel-item-pack {
    width: 90% !important;
  }
  .cursoDePack {
    width: 140px !important;
  }
  .VimeoOK {
    width: 350px;
  }
  .verSection button {
    font-size: 12px !important;
  }
  .controles button {
    margin: 2px !important;
  }
  .verSection {
    padding: 0px !important;
  }
  #micanvas {
    display: none;
  }
  .comprar__wrapper {
    width: 100% !important;
  }
  .contenedor-carrito {
    width: 100% !important;
    padding: 15px;
  }
}

.content {
  padding: 16px 16px 0px 16px !important;
}

.homeHero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.han {
  cursor: pointer;
}
a:hover {
  opacity: 0.8;
}
.poster-mobile {
  *width: 80%;
  *height: 80vh;
}

.regalin {
  margin-top: 3px;
  width: 250px;
  height: 30px;
  border-radius: 5px;
  border: none;
  font-family: "Abril Fatface", cursive;

  text-align: center;
  font-size: 18px;
}
.white {
  color: white;
}
.txtLetrita {
  font-family: "Abril Fatface", cursive;
}

.podcast {
  background-color: #282828;
}

.separador {
  margin: 0px;
  border: solid 0.5px white;
}
.comin {
  background: #01ff00;
  width: 100%;
  height: 100vh;
  z-index: 2272728;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cli {
  width: 80%;
}
.cursoGrati {
  font-family: "Abril Fatface", cursive;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
}

.contenidoCurso {
  padding: 15px;
}

#videoX {
  position: absolute;
  left: 0px;
  top: 55px;
  height: calc(100% - 70px);
  width: 100vw;
}

.summerIMG {
  max-width: 50vw;
}
.todoSummer {
  width: 100%;
  background: #181818;
  display: flex;

  justify-content: space-between;
}

.comprarStick {
  *height: 100vh;
  background: #fafafa;
  padding: 30px;
  *width: 400px;
}
.contieneIMG {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .summerIMG {
    max-width: 100%;
  }
  .contieneIMG {
    height: 100vh;
  }
  .todoSummer {
    width: 100%;
    height: 100vh;
    background: #181818;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .comprarStick {
    width: 100%;
    padding: 15px;
  }
}
