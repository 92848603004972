.soportePaquete {
  width: 80%;
  height: 120px;
  background-color: white;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  padding-left: 10px;
}
.planes {
  width: 150px;
}

.loguitoCositas {
  display: flex;
  flex-direction: column;
  height: 80px;
  padding-right: 10px;
}
.img-paquete {
  border-radius: 15px;
  width: 65px;
}
.tituloNombre {
  font-family: "Abril Fatface", cursive;
  padding: 0px;
  margin: 0px;
}
.contenedor-carrito {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  background: white;
}

.dataCompra {
  width: 90%;
  text-align: center;
}

.carrito-bruto {
  font-family: "Abril Fatface", cursive;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.carritoAhorro {
  font-family: "Abril Fatface", cursive;
  margin: 0px;
  padding: 0px;
}
.carritoTotal {
  font-family: "Abril Fatface", cursive;
  margin: 0px;
  padding: 0px;
  font-size: 30px;
}
.carritoDolares {
  font-family: "Abril Fatface", cursive;
  margin: 0px;
  padding: 0px;
}
.regalito {
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Abril Fatface", cursive;
}
.regalito input {
  font-size: 20px;
}
.descuentardos {
  font-family: "Abril Fatface", cursive;
}

.descuentardos button {
  margin: 10px;
}
.descuentardos input {
  height: 25px;
  width: 200px;
}
.tituloPago {
  font-size: 18px;
}
.titBTN {
  margin: 0px;
  padding: 0px;
}

.btnmp {
  margin-top: 50px;
  width: 150px;
  margin-bottom: 15px;
}
/* #buttons-container div:nth-child(2) {
  display: none;
} */
.pagosOtros {
  font-size: 24px;
  font-family: "Abril Fatface", cursive;
  margin-top: 100px;
  margin-bottom: 20px;
}
.btn-success {
  cursor: pointer;
  border: none;
}

.btn-success:hover {
  background: #07ad66;
  color: white;
}
.contenedor {
  width: 100%;
  display: flex;
  background: pink;
}
.regaloimg {
  width: 50px;
}
.transferencia {
  font-size: 14px;
}
.transferencia2 {
  font-size: 14px;
  width: 80%;
  text-align: center;
  background: #05ac00;
  color: white;
  padding: 10px;
  border-radius: 9px;
}
.ajuste {
  margin-top: -15px;
}
