footer a:visited {
  color: white !important;
}
footer a:hover {
  color: #01ff00 !important;
}

footer a {
  color: white;
}
