.esperar-imagen {
  width: 20%;
}

.contenedorExamenes {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.contenedorPre {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
}
.botonTermine {
  margin-top: 20px;
}
