.mercadopagobtn {
  height: 25px;
}
.btn-success {
  background: #23db8f;
  padding: 10px;
  border-radius: 4px;
  width: 100%;

  display: block;
}

.tarjetas {
  margin-top: 15px;
  width: 200px;
}
.formularioPAgo {
  background: white;
  padding: 30px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 3px;
  margin-top: 10px;
}
