.micurso-loguito {
  width: 130px;
}

.micurso-loguito-pendiente {
  width: 60px;
}
.item-curso {
  width: 200px;
  padding: 15px;
  border-radius: 15px;
  max-height: 330px;
  text-align: center;
}

.item-curso-pendiente {
  width: 200px;
  padding: 15px;
  border-radius: 15px;
  max-height: 145px;
  text-align: center;
}

.item-curso-pendiente p {
  padding: 0px;
  margin: 0px;
  margin-top: -15px;
}
.tituloH {
  height: 70px;
  max-width: 100%;
}
.programas {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 15px;
}
.imgTitCursos {
  max-width: 100%;
}
.header-curso img {
  width: 100px;
}

.programas .item-curso {
  min-height: 370px;
}
.enviarReto {
  position: absolute;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}
.topi {
  margin-top: 347px !important;
  position: absolute;
  margin-left: 25px !important;
}
.retitos {
  background: #ffabab;
  width: 200px;
  height: 140px;
  margin-bottom: 10px;
  margin-top: 190px;
  position: absolute;
}
.enviarReto input {
  border-radius: 5px;
  border: none;
  padding: 5px;
  width: 80%;
}
