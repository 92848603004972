.programa {
  display: flex;
  height: 30px;
}
.datita {
  font-size: 10px;
}
.programa img {
  width: 30px;
  height: 100%;
  padding-right: 10px;
}
.hrr {
  background: none;
  border: 1 px solid white;
}
