.header {
  padding: 10px 0px;
  width: 100%;
  color: white;
  background-color: #ff4500;

  z-index: 4;
  display: flex;
  justify-content: space-between;
}
.crearEntrar {
  padding-right: 15px;
  display: flex;
  align-items: center;
}
.creame {
  background: black;
  border: none;
  padding: 5px 20px 8px 20px;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  font-family: "Abril Fatface", cursive;
  font-size: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.creame:hover {
  background: #01ff00;
  color: black;
}

.vive {
  background: #01ff00;
  color: black;
}

.vive:hover {
  background: black;
  color: white;
}

.content {
  padding: 16px;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background: #ff4600;
}

.sticky + .content {
  padding-top: 102px;
}

.logo {
  width: 130px;
  height: 40px;
}
.header-menuHamburguesa {
}

.transparente {
  background: transparent;
  border: none;
}
.pp:hover {
  fill: white;
}

.ofertulis span {
  letter-spacing: 5px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Abril Fatface", cursive;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.oferta {
  text-align: center;
}

.ofertulis {
  height: 80px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.ofertulis .textos {
  width: 250px;
  font-family: "Abril Fatface", cursive;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.textos p {
  margin: 0px;
  padding: 0px;
  margin-top: -8px;
}

.tituloContador {
  line-height: 0px;
  padding-top: 15px !important;
  margin-bottom: -10px;
}

.rotacion {
  rotate: 5deg;
}
.poster {
  width: 100%;
  margin-bottom: -4px;
}
.carrito {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9;
  background: #45a9e0;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.numeroCarrito {
  color: white;
  position: absolute;
  font-family: "Abril Fatface", cursive;
  top: 0px;
  font-size: 15px;
  margin-left: 6px;
}
.soporteBoton {
  display: flex;
  font-family: "Abril Fatface", cursive;
  cursor: pointer;
}

.textoMArcoBTN {
  position: absolute;
  font-size: 15px;
  margin-left: 12px;
  margin-top: 2px;
}
.foto-perfil {
  border-radius: 10px;
  width: 35px;
  margin-right: 10px;
  cursor: pointer;
}
.dslogo {
  height: 30px;
  cursor: pointer;
}
.transparente {
  cursor: pointer;
}
.ofertulis {
  height: 66px;
}
