.curso {
  width: 100%;
  height: 150px;
  background: pink;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.curso h1 {
  font-family: "Abril Fatface", cursive;

  margin: 0px;
  padding: 0px;
}
.curso__img {
  width: 70px;
}

.curso_informacion {
  display: flex;
  align-items: flex-start;
  width: 60%;
  flex-direction: column;
  justify-content: center;
}
.curso__descripcion {
  margin: 0px;
}
.curso__titulo {
  font-size: 16px;
}

.boton-comprar {
  font-family: "Abril Fatface", cursive;
}
.curso--oferta {
  font-size: 10px;
  margin: 0px;
  padding: 0px;
  color: red;
}
.curso__precio {
  font-size: 16px;
  width: 170px;
  display: flex;
  padding: 5px 0px 0px 0px;
  align-items: center;
  flex-direction: column;
  color: white;
  font-family: "Abril Fatface", cursive;
  height: 90px;
}

.curso__precio .preciou {
  font-size: 16px;
}
.infoyfoto {
  display: flex;
}

footer {
  background: black;
  color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

footer a {
  padding: 5px;
}
.contenido-footer {
  width: 500px;
  font-size: 14px;
}
.regalin {
}

.regalines {
  width: 400px;
  height: 200px;
  padding: 20px 15px 15px 0px;
}
.cursocontenedor {
}
.precioCurso {
  margin: 0px;
  margin-bottom: 5px;
}
.datosCurso {
  padding: 15px;
}
.tituloCurso {
  margin: 0px;
}

.header-curso {
  display: flex;
  justify-content: space-between;
}

.curso--oferta {
  color: white;
  font-size: 12px;
  margin-bottom: 5px;
}

.aclara {
  font-size: 12px;
  color: red;
}
