.loader-wraper {
  background: #ff4500;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 10000000;
  /* *filter: blur(4px); */
  top: 0px;
}
.loader-img {
  width: 125px;
}
