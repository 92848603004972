.cursoDePack {
  width: 150px;
  border-radius: 20px;
  padding: 10px;
}

.carousel-item-pack__details--title {
  font-family: "Abril Fatface", cursive;
  font-size: 16px;
  border-radius: 20px;
}

.carousel-item-pack {
  width: 40%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 10px;
}
