.clorcito {
  background: pink;
  width: 70%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contenido-loco {
  display: flex;
  flex-direction: row;
}
.comprar__wrapper {
  display: flex;
  width: 30%;
  flex-direction: column;
  padding: 20px;
}

.piz {
  width: 80%;
}
