.zombie {
  background: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  padding: 15px;
  border-radius: 15px;
  max-height: 330px;
  flex-direction: column;
  color: white;
  font-size: 14px;
}
.logocz {
  width: 150px;
}
