@font-face {
  font-family: "ARCADECLASSIC";
  src: url("../fonts/ARCADECLASSIC.eot");
  src: url("../fonts/ARCADECLASSIC.eot") format("embedded-opentype"),
    url("../fonts/ARCADECLASSIC.woff2") format("woff2"),
    url("../fonts/ARCADECLASSIC.woff") format("woff"),
    url("../fonts/ARCADECLASSIC.ttf") format("truetype"),
    url("../fonts/ARCADECLASSIC.svg#ARCADECLASSIC") format("svg");
}
.ganadores {
  background-color: #111;
  font-family: "ARCADECLASSIC";
  font-size: 32px;
  height: 100vh;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
}
.ganadores-h1 {
  color: #0d1bb4;
  font-size: 40px;
}
.ganadores-lista {
  display: flex;
  color: #b7120b;
}

.ganadores-columnaNombres {
  padding-left: 0px;
}
.ganadores- {
  padding-left: 0px;
}
.nombres {
  padding: 15px;
  padding-right: 40px;
}
.fecha {
  padding: 15px;
  padding-left: 40px;
}
.ganadores-li {
  list-style: none;
}
.ganadores-pd {
  color: #0d1bb4;
}
.ganadores-columnaNombres {
  line-height: 37px;
}

@media (max-width: 768px) {
  .ganadores {
    font-size: 14px;
  }
  .ganadores-h1 {
    font-size: 32;
  }
  .ganadores-nombres {
    padding: 15px;
  }
  .ganadores-columnaFecha {
    padding: 15px;
    padding-left: 20px;
  }
  .ganadores-columnaNombres {
    line-height: 17px;
  }
}
