.cta .btn-loco {
  border: none;
}

.cta {
  width: 190px;
  border-radius: 6px;
  padding: 7px;
  text-align: center;
  font-size: 18px;
  position: absolute;
  margin-bottom: 15px;
  font-family: "Abril Fatface", cursive;
}

.cta:hover {
  color: white !important;
  background-color: #01ff00 !important;
}

.ver {
  cursor: pointer;
  font-size: 14px;
}

.ver:hover {
  color: black;
}

.contenedorBanner {
  width: 100%;
  height: 92vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}
.ctaDesktop {
  width: 200px;
  cursor: pointer;
}

.ppp {
  height: 100%;
}
