.contenedor-mi-perfil {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  align-items: flex-end;
}

.contenedor-mi-perfil input {
  padding-top: 10px;
  padding: 3px;
  border-radius: 5px;
}

.foto-de-perfil {
  margin-top: 15px;
  border-radius: 15px;
  width: 260px;
}
.listado-avatares {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 15px;
}
.avatarito {
  width: 100px;
  border-radius: 15px;
}

select {
  width: 264px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
}

#quieroNews {
  width: 30px;
  padding-left: 0px;
  margin-left: 0px;
}

.quiero {
  display: flex;
  align-items: center;
  height: 60px;
  margin-left: 0px;
  padding-left: 0px;
}

.btn-loco {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border: solid 1px #01ff00;
  font-family: "Abril Fatface", cursive;
  border-radius: 5px;
  margin: 3px;
  background: white;
}

.codealoco {
  width: 60%;
  padding: 50px;
  padding-bottom: 100px;
}
.direccion {
  font-size: 12px;
}
.contenedorPerfil {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.colorcito {
  width: 70%;
  background-color: #01ff00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datasPerfil {
  padding: 25px;
  display: flex;
  flex-direction: column;
  width: 30%;
}
.datasPerfil input {
  width: 100%;
  height: 20px;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: solid 1px #777;
}
#comboPais {
  width: 100%;
}
.logoMeta {
  width: 200px;
}

@media only screen and (max-width: 800px) {
  .datasPerfil {
    width: 100%;
  }
}
